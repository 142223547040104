var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    data: function () {
        return {
            timeoutId: -1,
        };
    },
    apollo: {
        propertyListingPayment: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PaymentSuccessQuery($id: ID!) {\n          propertyListingPayment(id: $id) {\n            id\n            isPaid\n          }\n        }\n      "], ["\n        query PaymentSuccessQuery($id: ID!) {\n          propertyListingPayment(id: $id) {\n            id\n            isPaid\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.id,
                };
            },
            result: function (result) {
                var _this = this;
                if (this.$ssrContext) {
                    return;
                }
                if (result.data &&
                    result.data.propertyListingPayment &&
                    result.data.propertyListingPayment.isPaid) {
                    return;
                }
                this.timeoutId = window.setTimeout(function () { return _this.$apollo.queries.propertyListingPayment.refetch(); }, 2000);
            },
        },
    },
    beforeDestroy: function () {
        if (!this.$ssrContext) {
            window.clearTimeout(this.timeoutId);
        }
    },
});
var templateObject_1;
